<template>
	<b-card-group deck>
		<b-card>
			<b-row>
				<b-col>
					<h4 class="mb-1">
						{{ translate('update_credit_card') }}
					</h4>
				</b-col>
			</b-row>
			<hr class="mt-2 mb-4">
			<b-row>
				<b-col>
					<div>
						<div class="form-group mb-0">
							<form class="w-100">
								<div class="form-group">
									<label for="cc-v2-name">{{ translate('name_on_card') }} <span class="text-danger">*</span></label>
									<span
										id="cc-v2-name"
										:class="lacoreV2CreditCardHasErrors('name') ? 'is-invalid' : ''"
										class="form-field form-control" />
									<template v-if="lacoreV2CreditCardHasErrors('name')">
										<p
											v-for="error in lacoreV2CreditCardErrors.name.errorMessages"
											:key="error"
											class="custom-invalid-feedback animated fadeIn text-danger mb-0"
											v-text="error" />
									</template>
								</div>
								<div class="form-group">
									<label for="cc-v2-number">{{ translate('card_number') }} <span class="text-danger">*</span></label>
									<span
										id="cc-v2-number"
										:class="lacoreV2CreditCardHasErrors('number') ? 'is-invalid' : ''"
										class="form-field form-control" />
									<template v-if="lacoreV2CreditCardHasErrors('number')">
										<p
											v-for="error in lacoreV2CreditCardErrors.number.errorMessages"
											:key="error"
											class="custom-invalid-feedback animated fadeIn text-danger mb-0"
											v-text="error" />
									</template>
								</div>
								<div class="row">
									<div class="form-group col-12 col-md-4">
										<label for="cc-v2-expiration-month">{{ translate('expiry_month') }} <span class="text-danger">*</span></label>
										<span
											id="cc-v2-expiration-month"
											:class="lacoreV2CreditCardHasErrors('expiration_month') ? 'is-invalid' : ''"
											class="form-field form-control" />
										<template v-if="lacoreV2CreditCardHasErrors('expiration_month')">
											<p
												v-for="error in lacoreV2CreditCardErrors.expiration_month.errorMessages"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger mb-0"
												v-text="error" />
										</template>
									</div>
									<div class="form-group col-12 col-md-4">
										<label for="cc-v2-expiration-year">{{ translate('expiry_year') }} <span class="text-danger">*</span></label>
										<span
											id="cc-v2-expiration-year"
											:class="lacoreV2CreditCardHasErrors('expiration_year') ? 'is-invalid' : ''"
											class="form-field form-control" />
										<template v-if="lacoreV2CreditCardHasErrors('expiration_year')">
											<p
												v-for="error in lacoreV2CreditCardErrors.expiration_year.errorMessages"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger mb-0"
												v-text="error" />
										</template>
									</div>
									<div class="form-group col-12 col-md-4">
										<label for="cc-v2-cvc">{{ translate('cvv') }} <span class="text-danger">*</span></label>
										<span
											id="cc-v2-cvc"
											:class="lacoreV2CreditCardHasErrors('security_code') ? 'is-invalid' : ''"
											class="form-field form-control" />
										<template v-if="lacoreV2CreditCardHasErrors('security_code')">
											<p
												v-for="error in lacoreV2CreditCardErrors.security_code.errorMessages"
												:key="error"
												class="custom-invalid-feedback animated fadeIn text-danger mb-0"
												v-text="error" />
										</template>
									</div>
								</div>
							</form>
						</div>
					</div>
				</b-col>
				<div class="col-12">
					<div class="row">
						<div class="col-2 col-sm-auto pr-0">
							<switch-toggle
								id="default_card"
								v-model="form.is_default"
								name="default_card"
								variant="success"
								pill />
						</div>
						<div class="col-10 col-lg d-flex align-items-end">
							<label
								for="default_card"
								class="mb-0">{{ translate('default_toggle') }}</label>
						</div>
					</div>
				</div>
			</b-row>
			<hr>
			<b-row>
				<b-col>
					<address-radio-button
						:address-data="addressData"
						:address-loading="addressLoading"
						:fetching-card-loading="fetchingCardLoading"
						:form-import="form"
						:hide-new-address="addAddress"
						:display-add-new-address="false"
						@add-address="addressFormState" />
					<transition
						name="component-fade"
						mode="in-out">
						<address-country-form
							v-if="addAddress && !addressLoading"
							:user-country="SHOW_USER_COUNTRY"
							:form-import="form.address"
							:errors-import="errors.errors"
							:prefix-errors="'address.'"
							:display-cancel-button="false"
							:display-submit-button="false"
							:display-goback-button="false" />
					</transition>
				</b-col>
			</b-row>
			<b-row
				v-if="(typeof errors.errors['address_id'] !== 'undefined')">
				<b-col>
					<b-alert
						variant="danger"
						show>
						<div
							v-for="error in errors.errors['address_id']"
							:key="error">
							<span
								v-text="error" />
							<br>
						</div>
					</b-alert>
				</b-col>
			</b-row>
			<b-row>
				<b-col
					v-if="!addressLoading"
					:class="['xs', 'sm'].includes(windowWidth) ? 'mb-3' : ''"
					class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-center">
					<button
						v-if="addAddress && hasAddressData"
						:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
						class="btn btn-secondary b-4 float-left"
						@click="addressFormState">
						{{ translate('see_address_list') }}
					</button>
					<button
						v-else-if="!addAddress"
						:class="['xs', 'sm'].includes(windowWidth) ? 'w-100' : ''"
						class="btn btn-secondary b-4 float-left"
						@click="addressFormState">
						{{ translate('use_another_address') }}
					</button>
				</b-col>
				<b-col>
					<button
						:disabled="(!hasAddressData && !addAddress) || updateCreditLoading"
						:class="['xs', 'sm'].includes(windowWidth) ? 'w-48' : 'ml-2'"
						class="btn btn-primary b-4 float-right"
						@click="updateCreditCard(form)">
						{{ translate('submit') }}
					</button>
					<button
						:class="['xs', 'sm'].includes(windowWidth) ? 'w-48 float-left' : 'float-right'"
						class="btn btn-secondary b-4"
						@click="$router.push({ name: 'AllCreditCards' })">
						{{ translate('go_back') }}
					</button>
				</b-col>
			</b-row>
		</b-card>
	</b-card-group>
</template>
<script>

import AddressRadioButton from '@/components/AddressRadioButton';
import AddressCountryForm from '@/components/AddressCountryForm';
import SwitchToggle from '@/components/Switch';
import WindowSizes from '@/mixins/WindowSizes';
import { Profile, Validations, Addresses } from '@/translations';
import CreditCard from '@/util/CreditCard';
import AddressBook from '@/util/AddressBook';
import Country from '@/util/Country';
import { SHOW_USER_COUNTRY } from '@/settings/Country';
import { SYSTEM_ALERT, UNPROCESSABLE } from '@/settings/Errors';
import CreditCardFormV2 from './mixins/CreditCardFormV2';
import EventBus from '@/util/eventBus';

export default {
	name: 'UpdateCreditCard',
	messages: [Profile, Validations, Addresses],
	components: {
		AddressRadioButton,
		AddressCountryForm,
		SwitchToggle,
	},
	mixins: [CreditCardFormV2, WindowSizes],
	data() {
		return {
			form: {
				first_name: '',
				last_name: '',
				expiry_month: 1,
				expiry_year: this.$moment().year(),
				cvv: '',
				cc_number: '',
				card_type: '',
				address_id: '',
				is_default: false,
				address: {
					first_name: '',
					last_name: '',
					address: '',
					address2: '',
					city: '',
					postcode: '',
					country_code: '',
					region_id: '',
				},
			},
			creditCard: new CreditCard(),
			fetchingCreditCard: new CreditCard(),
			alert: new this.$Alert(),
			addressBook: new AddressBook(),
			// cardLoading: true,
			updateAddressBook: new AddressBook(),
			getSuggestion: new AddressBook(),
			country: new Country(),
			states: new Country(),
			addAddress: false,
			SHOW_USER_COUNTRY,
			getBillingDetails: new AddressBook(),
			state: new Country(),
			countryIso: new Country(),
			paymentProcessor: process.env.VUE_APP_CC_PAYMENT_PROCESSOR,
		};
	},
	computed: {
		errors() {
			return this.creditCard.data.errors;
		},
		addressLoading() {
			return !!this.addressBook.data.loading;
		},
		fetchingCardLoading() {
			return !!this.fetchingCreditCard.data.loading;
		},
		updateCreditLoading() {
			return !!this.creditCard.data.loading;
		},
		addressData() {
			try {
				const { data } = this.addressBook.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasAddressData() {
			const response = this.addressData.length;
			return !!response;
		},
		countryData() {
			try {
				const { data } = this.country.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		stateData() {
			try {
				const { data } = this.states.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
	},
	watch: {
		hasAddressData: {
			deep: true,
			handler(newVal) {
				this.addAddress = !newVal;
			},
		},
		'form.address_id': function addressId() {
			if (Object.keys(this.errors.errors).length > 0) {
				delete this.errors.errors.address_id;
				this.errors.errors = { ...this.errors.errors };
			}
		},
	},
	mounted() {
		this.initialize();
	},
	methods: {
		initialize() {
			if (this.paymentProcessor === 'LPT') {
				this.lacoreV2FormSetup();
			}
			this.addressBook.getAddresses(this.$user.details().id).then((addressResponse) => {
				const addressID = (addressResponse.length) ? addressResponse[0].id : '';
				this.fetchingCreditCard.getCreditCard(this.$user.details().id, this.$route.params.credit_card_id).then((response) => {
					this.form.first_name = response.attributes.cardholder_first_name;
					this.form.last_name = response.attributes.cardholder_last_name;
					if (response.attributes.address.id === undefined) this.form.address_id = addressID;
					if (response.attributes.address.id !== undefined) this.form.address_id = response.attributes.address.id.toString();
					// this.cardLoading = this.fetchingCardLoading;
					if (response.attributes.is_default === 1) this.form.is_default = true;
				});
			});
			this.country.getCountries().then(() => {
				this.form.address.country_code = this.$user.details().country.iso_code_2;
				this.states.getStates(this.$user.details().country.iso_code_2).then((response) => {
					if (response.length) this.form.address.region_id = response[0].id;
				});
			});
		},
		async updateCreditCard(form) {
			const options = {
				allowOutsideClick: false,
				allowEscapeKey: false,
				allowEnterKey: false,
			};
			this.alert.loading(this.translate('loading_title'), this.translate('loading_text'), options);
			let payload = '';
			if (this.paymentProcessor === 'GPG') {
				payload = { ...form };
				payload.address = undefined;
				payload.address_id = undefined;
				payload.expiry_month = this.$moment().month(payload.expiry_month - 1).format('MM');
				payload.expiry_year = this.$moment().year(payload.expiry_year).format('YY');
			} else {
				await this.lacoreV2HandleResponse();
				payload = { is_default: form.is_default, token: this.lacoreV2PaymentToken };
			}
			if (this.addAddress) {
				payload.address = form.address;
			} else {
				payload.address_id = form.address_id;
			}
			this.creditCard.updateCreditCard(this.$user.details().id, this.$route.params.credit_card_id, payload).then(() => {
				this.alert.toast('success', this.translate('credit_card_updated'), { timer: 6000 });
				this.$router.push({ name: 'AllCreditCards' });
			}).catch(() => {
				this.form = form;
				if (SYSTEM_ALERT.includes(this.errors.status) || this.errors.status === undefined) {
					this.alert.toast('error', this.translate('default_error_message'), { timer: 6000 });
				} else if (UNPROCESSABLE.includes(this.errors.status)) {
					if (typeof this.errors.errors.token !== 'undefined') {
						if (this.lacoreV2CreditCardErrors.number) {
							this.lacoreV2CreditCardErrors.number.errorMessages.push(...this.errors.errors.token);
						}
					}
					this.alert.close();
				} else {
					this.alert.close();
				}
			}).finally(() => {
				this.creditCard.data.loading = false;
				EventBus.$emit('updateAccountSteps');
			});
		},
		onCountryChange(countryCode) {
			const { code } = this.countryData.find((item) => item.attributes.code === countryCode).attributes;
			if (typeof code !== 'undefined') {
				this.states.getStates(code).then((response) => {
					const [state] = response;
					if (typeof state === 'object') {
						this.form.address.region_id = '';
					}
				});
			}
		},
		addressFormState() {
			if (Object.keys(this.errors.errors).length > 0) {
				const errorFields = Object.keys(this.errors.errors);
				errorFields.forEach((field) => {
					delete this.errors.errors[field];
					this.errors.errors = { ...this.errors.errors };
				});
			}
			this.addAddress = !this.addAddress;
		},
	},
};
</script>

<style>
	.w-48 {
		width: 48%;
	}
</style>
